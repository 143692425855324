body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
 font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.asterisk {
  font-size: small;
  color: red;
}

.modal-body {
  font-size: 14px;
}

.modal-body-bottom {
  font-size: 12px;
  bottom: 0;
}

.ticket-reply-btn {
  width: 150px;
  float: right;
}

.mydas-primary {
  color: #183287 !important;
}

.mydas-secondary {
  color: #ff9700 !important;
}

.mydas-btn-primary {
  background: #183287 !important;
  float: right;
}

.mydas-btn-secondary {
  background: #ff9700 !important;
  border: none;
}

.nav-link.active {
  background: #ff9700 !important;
  border: none;
}

.card-primary.card-outline {
  border-top: 3px solid #183287;
}

.bg-teal {
  background: #183287 !important;
}

.a {
  color: #183287 !important;
}

.btn-primary {
  color: #fff;
  background-color: #183287;
  border-color: #183287;
  box-shadow: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}

.bg-blue {
  background-color: #183287 !important;
}

/* ////////////////////////// */
#select-num-cols{
  padding: 5px; 
  border: 2px solid #0E4D92; 
  border-radius: 5px;
  cursor: pointer;
  text-align: center; 
  font-size: 50px;
  color: #0E4D92;
}

#select-num-cols1{
  padding: 5px; 
  border: 2px solid #0E4D92; 
  border-radius: 5px;
  cursor: pointer;
  text-align: center; 
  font-size: 30px;
  color: #0E4D92;
}

.margin-bottom{
  margin-bottom: 25px;
}

.margin-left{
  margin-left: 15px;
}

.margin-15{
  margin-top: 15px;
}

.menu-pages{
  margin-bottom: -5px;
}

.menu-label{
  font-weight: none;
}

.menu-padding{
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
}

.padding-round{
	padding: 25px;
}

.margin-bottom {
  margin-bottom: 5px;
}

.product {
  margin-bottom: 50px;
  cursor: pointer;
}

.main-content {
  padding: 50px 0px;
}

.product-description {
  margin-top: 15px;
}

.card-footer {
  display: flow-root;
}

.price {
  float: left;
  font-weight: 700;
  color: firebrick;
}

.buy-now {
  float: right;
}

.mydas-secondary-color {
  color: firebrick;
}

.duration {
  font-size: 12px;
  color: #183287;
  font-weight: 500;
}

.expiration-time {
  float: right;
  font-size: 12px;
  color: firebrick;
  margin-top: 5px;
  font-weight: 500;
}

.allowed-students {
  float: right;
  font-size: 12px;
  color: #183287;
  margin-top: 5px;
  font-weight: 500;
}

.status-expired {
  color: firebrick;
}

.status-active {
  color: #183287;
}
/* //////////////////////////////////////////////////// */
.swal2-icon.swal2-warning, .swal2-icon.swal2-info, .swal2-icon.swal2-question {
  margin: .333333em auto .5em;
  font-family: inherit;
  font-size: 0.75em !important;
}

/* .swal2-icon-warning {
  background-color: gold !important;
  color: #ffffff !important;
}

.swal2-warning {
  color: #ffffff !important;
}

.swal2-icon.swal2-warning {
  border-color: #ffffff !important;
}

.swal2-x-mark-line-left {
  background-color: #ffffff !important;
}

.swal2-x-mark-line-right {
  background-color: #ffffff !important;
}

.swal2-icon-error {
  background: #f27474 !important;
  color: #ffffff !important;
}

.swal2-error {
  background: maroon !important;
}

.swal2-title {
  color: #ffffff !important;
}

.swal2-icon-success {
  background: #28a745 !important;
  color: #ffffff !important;
}

.swal2-success {
  background: #a5dc86 !important;
}

.swal2-title {
  color: #ffffff !important;
}

.swal2-success-line-long {
  background: #ffffff;
}

.swal2-success-line-tip {
  background: #ffffff;
} */